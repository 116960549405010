import api from "./_req";

export default {
  fetchWidgetData(data, cb) {
    api.get(cb, data, `/dashboard/transactions-widget/${data.type}`);
  },

  fetchWidgetCounts(type, cb) {
    api.get(cb, {}, `/dashboard/widget-counts/${type}`);
  },
};
