import api from "./_req";

export default {
  fetch(data, cb) {
    api.get(cb, data, "/confirmed-bills");
  },

  create(data, cb) {
    api.post(cb, data, `/confirmed-bills`);
  },

  update(id, data, cb) {
    api.post(cb, data, `/confirmed-bills/${id}?_method=PUT`);
  },

  delete(id, cb) {
    api.delete(cb, `/confirmed-bills/${id}`);
  },

  list(data, cb) {
    api.get(cb, data, "/confirmed-bills/list");
  },

  import(data, cb) {
    api.post(cb, data, `/confirmed-bills/import`);
  },

  export(mime, data, cb) {
    api.download(cb, { ...data, mime }, `/confirmed-bills/export/${mime}`);
  },
};
