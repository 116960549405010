<template>
  <div>
    <transition name="fade-anim">
      <AppLoader v-if="appLoading" />
    </transition>

    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/components/base/AppLoader";

export default {
  components: {
    AppLoader,
  },

  computed: {
    ...mapGetters({
      appLoading: "appLoading",
    }),

    layout() {
      // Dynamic layout loading.
      return (this.$route.meta.layout || "default") + "-layout";
    },
  },
};
</script>

<style>
.fade-anim-enter-active,
.fade-anim-leave-active {
  transition: opacity 0.275s;
}
.fade-anim-enter,
.fade-anim-leave-to {
  opacity: 0;
}
</style>
