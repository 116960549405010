import api from "./_req";

export default {
  fetch(data, cb) {
    api.get(cb, data, "/store-brands");
  },

  create(data, cb) {
    api.post(cb, data, `/store-brands`);
  },

  update(id, data, cb) {
    api.post(cb, data, `/store-brands/${id}?_method=PUT`);
  },

  delete(id, cb) {
    api.delete(cb, `/store-brands/${id}`);
  },

  list(data, cb) {
    api.get(cb, data, "/store-brands/list");
  },

  import(data, cb) {
    api.post(cb, data, `/store-brands/import`);
  },

  export(mime, data, cb) {
    api.download(cb, { ...data, mime }, `/store-brands/export/${mime}`);
  },
};
