import api from "./_req";

export default {
  fetch(data, cb) {
    api.get(cb, data, "/stores");
  },

  create(data, cb) {
    api.post(cb, data, `/stores`);
  },

  update(id, data, cb) {
    api.post(cb, data, `/stores/${id}?_method=PUT`);
  },

  delete(id, cb) {
    api.delete(cb, `/stores/${id}`);
  },

  list(data, cb) {
    api.get(cb, data, "/stores/list");
  },

  import(data, cb) {
    api.post(cb, data, `/stores/import`);
  },

  export(mime, data, cb) {
    api.download(cb, { ...data, mime }, `/stores/export/${mime}`);
  },
};
