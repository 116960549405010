import api from "./_req";

export default {
  fetch(data, cb) {
    api.get(cb, data, "/invoices");
  },

  fetchOne(id, cb) {
    api.get(cb, {}, `/invoices/${id}`);
  },
};
