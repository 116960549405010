import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const API_URL = process.env.VUE_APP_API_URL;

export default {
  get(callback, params, route) {
    axios
      .get(API_URL + route, {
        params: params ? params : {},
      })
      .then((response) => callback(response))
      .catch((error) => callback(error.response));
  },

  post(callback, data, route) {
    axios
      .post(API_URL + route, data)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error.response);
      });
  },

  delete(callback, route) {
    axios
      .delete(API_URL + route)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(error.response);
      });
  },

  download(callback, params, route) {
    const MIME = params.mime || "csv";
    axios
      .get(API_URL + route, {
        params: params ? params : {},
        responseType: "arraybuffer",
      })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${uuidv4().toString()}.${MIME}`);
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();

        callback(response);
      })
      .catch((error) => callback(error.response));
  },
};
